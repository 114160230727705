nav {
  .navbar-brand {
    width: 250px;

    @media(max-width: 991px) {
      width: 180px;
    }
  }

  .menu-menu-container {
    margin-left: auto;

    @media(max-width: 991px) {
      margin: 10px 0;
    }

    .menu-item {
      font-size: 14px;
      padding: 10px;
      display: inline-flex;

      @media(max-width: 991px) {
        padding: 0;
        margin: 5px 0;
      }

      a {
        font-weight: 500;
        color: #808080;
        text-decoration: none;
      }
    }
  }
}