@import "../../assets/style/index.scss";
@import '../../node_modules/tiny-slider/dist/tiny-slider.css';
//@import "../../../node_modules/aos/dist/aos.css";
@import "../../components/navigation/navigation.scss";
@import "../../components/footer/footer.scss";
//@import "../../components/cookie-bar/cookie-bar.scss";

main {
  .header-section {
    padding: 55px 0 0 0;

    .header-slider {
      article {
        min-height: 700px;


        .bg {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          &.bg-down {
            background-position: center -150px;
          }
        }


        @media(max-width: 991px) {
          min-height: auto;
        }

        h1 {
          @media(max-width: 991px) {
            font-size: 34px;
          }
        }
      }
    }

    .tns-nav {
      display: flex;
      padding: 20px 0;
      justify-content: center;
      button {
        margin: 0 3px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        border: 0;
        background-color: #BDBDBD;

        &.tns-nav-active {
          background-color: #1D3557;
        }
      }
    }
  }

  .about-section {
    padding: 100px 0;

    h2 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }


    @media(max-width: 991px) {
      padding: 50px 0;
    }
  }

  .numbers-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }


    h2 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }

    div {
      @media(max-width: 991px) {
        border-right: none !important;
      }
    }
  }

  .service-section {
    padding: 100px 0;

    h2 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }

    @media(max-width: 991px) {
      padding: 50px 0;
    }
  }

  .investments-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }

    .head-info {
      width: 800px;

      @media(max-width: 991px) {
        width: 100%;
      }
    }

    @media(max-width: 991px) {
      padding: 50px 0;
    }
  }

  .realizations-section {
    padding: 100px 0;

    h2 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }


    @media(max-width: 991px) {
      padding: 50px 0;
    }
  }
}