@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');

body {
  font-family: 'Montserrat', sans-serif;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-900 {
  font-weight: 900;
}

.font-size-90 {
  font-size: 90px;
}

.font-size-64 {
  font-size: 64px;
}

.font-size-50 {
  font-size: 50px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-42 {
  font-size: 42px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-10 {
  font-size: 10px;
}

.list-style-none {
  list-style: none;
}

.cursor-pointer {
  cursor: pointer;
}

.line-height-50 {
  line-height: 50px;
}

.line-height-44 {
  line-height: 44px;
}

.line-height-38 {
  line-height: 38px;
}

.line-height-34 {
  line-height: 34px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-22 {
  line-height: 22px;
}

.wysiwyg-container {
  color: #737373;
  font-size: 14px;

  .wp-block-button__link {
    color: #ffffff !important;
  }

  .wp-caption {
    width: unset !important;
    max-width: 100% !important;
  }

  a {
    color: #1D3557;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
      text-decoration-color: #1D3557;
    }
  }

  blockquote {
    font-style: italic;
    margin-left: 30px;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  h5 {
    font-size: 18px;
    color: #1D3557;
    margin: 30px 0 20px 0;
  }

  h4 {
    font-size: 20px;
    color: #1D3557;
    margin: 30px 0 20px 0;
  }

  h3 {
    font-size: 25px;
    color: #1D3557;
    margin: 30px 0 20px 0;
  }

  h2 {
    font-size: 30px;
    color: #1D3557;
    margin: 30px 0 20px 0;
  }

  h1 {
    font-size: 35px;
    color: #1D3557;
    margin: 30px 0 20px 0;
  }

  p {
    color: #737373;
    line-height: 26px;
    font-size: 16px;
  }

  small {
    font-size: 14px;
    color: #737373;
  }

  ul, ol {
    padding: 0;
    margin-left: 21px;
    color: #737373;
    font-size: 16px;

    li {
      margin-bottom: 10px;
    }
  }
}
